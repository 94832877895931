
import {ref, watch} from "vue";

export default {
  name: "visualizarVendasProduto",

  props: {
    consultorProduto: {
      type: Object,
      required: true,
    }
  },

  setup(props) {
    const consultoresProdutoTotal = ref();

    watch(() => props.consultorProduto, (newValue) => {
      consultoresProdutoTotal.value = newValue;
    })

    return {
      consultoresProdutoTotal,
    }

  }
}
