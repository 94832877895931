
import {ref, watch} from "vue";

export default {
  name: "visualizarProdutosConsultor",

  props: {
    consultorProduto: {
      type: Object,
      required: true,
    }
  },

  setup(props) {
    const consultorProdutoSeparado = ref();

    watch(() => props.consultorProduto, (newValue) => {
      consultorProdutoSeparado.value = newValue;
    })

    return {
      consultorProdutoSeparado,
    }

  }
}
